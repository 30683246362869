const Checkbox = ({ selected, onChange }) => {
  return (
    <div
      className="p-3 border border-black/60 rounded-md font-bold relative outline-none z-0 w-full flex gap-2"
      onClick={() => onChange({ target: { checked: !selected } })}
    >
      <input type="checkbox" checked={selected} />
      <p>Show zero balances</p>
    </div>
  );
};

export default Checkbox;

const Search = ({ onChange, value, label }) => {
  return (
    <div className="relative w-full">
      <p className="absolute -top-4 left-3 px-2 text-base text-black z-[1] bg-white">
        {label}
      </p>
      <input
        className="p-3 border border-black/60 rounded-md font-bold relative outline-none z-0 w-full"
        placeholder="Search"
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default Search;

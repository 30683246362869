import { createColumnHelper } from "@tanstack/react-table"

const columnHelper = createColumnHelper()

export const holdColumns = [
  columnHelper.accessor("id", {
    header: () => "ID",
  }),
  columnHelper.accessor("account", {
    header: () => "Account",
  }),
  columnHelper.accessor("symbol", {
    header: () => "Symbol",
  }),
  columnHelper.accessor("type", {
    header: () => "Type",
  }),
  columnHelper.accessor("name", {
    header: () => "Name",
  }),
  columnHelper.accessor("inOrder", {
    header: () => "In-Order",
  }),
  columnHelper.accessor("withdrawing", {
    header: () => "Withdrawing",
  }),
  columnHelper.accessor("available", {
    header: () => "Available",
  }),
]

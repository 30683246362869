import React, { useState, useEffect } from "react";
import Button from "../widgets/Button";
import IconExternalLink from "../assets/icons/IconExternalLink";
import ConfirmationModal from "./ConfirmationModal";

const EditInventoryModal = ({ isOpen, onClose, inventoryItem, onSave }) => {
  const [formData, setFormData] = useState({
    registryProject: "",
    registryRecord: "",
    startId: "",
    endId: "",
    assetName: "",
    ...inventoryItem,
  });

  const [error, setError] = useState(null);
  const [isConfirmationStep, setIsConfirmationStep] = useState(false); // Track modal step
  const [editedFields, setEditedFields] = useState({}); // Track all edited fields
  const [modifiedFields, setModifiedFields] = useState({}); // Track only current session edits

  useEffect(() => {
    if (inventoryItem) {
      setFormData({
        registryProject: inventoryItem.registryProject || "",
        registryRecord: inventoryItem.registryRecord || "",
        startId: inventoryItem.startId || "",
        endId: inventoryItem.endId || "",
        assetName: inventoryItem.assetName || "",
        ...inventoryItem,
      });
      setEditedFields({}); // Reset editedFields when opening a new tranche
      setModifiedFields({}); // Reset modifiedFields for current session edits
    }
  }, [inventoryItem]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update formData
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Track all edits (compared to original inventoryItem)
    setEditedFields((prev) => ({
      ...prev,
      [name]: value !== inventoryItem[name] ? value : undefined,
    }));

    // Track current session edits (explicit user changes)
    setModifiedFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (isNaN(formData.startId)) {
      setError("Start ID must be numeric.");
      return;
    }

    if (isNaN(formData.endId)) {
      setError("End ID must be numeric.");
      return;
    }

    if (Number(formData.startId) >= Number(formData.endId)) {
      setError("Start ID must be smaller than End ID.");
      return;
    }

    setError(null);
    setIsConfirmationStep(true); // Move to confirmation step
  };

  const handleConfirmSave = () => {
    onSave(formData); // Save the data
    setIsConfirmationStep(false); // Close confirmation step
    onClose(); // Close the entire modal
  };

  // Check if any fields in `modifiedFields` are different from initial values
  const hasNewEdits = Object.keys(modifiedFields).some(
    (key) => modifiedFields[key] !== inventoryItem[key]
  );

  const isSaveDisabled = !hasNewEdits; // Disable Save if no new edits

  // Filter out fields to avoid duplication
  const trancheDataKeys = Object.keys(formData).filter(
    (key) =>
      !["registryProject", "registryRecord", "startId", "endId", "assetName"].includes(key)
  );

  if (!isOpen && !isConfirmationStep) return null; // Ensure modals are closed when both steps are inactive

  return (
    <>
      {!isConfirmationStep ? (
        <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="modal-content bg-white rounded-lg shadow-xl p-8 w-full max-w-2xl relative z-60">
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">Edit Inventory Item</h2>

            {/* Display Tranche Data (filtered) */}
            <div className="space-y-4 mb-6">
              {trancheDataKeys.map((key) => (
                <div key={key} className="flex justify-between items-center">
                  <span className="text-lg font-bold text-gray-800 capitalize">
                    {key.replace(/([A-Z])/g, " $1")}
                  </span>
                  {key === "creationUrl" ? (
                    <a
                      href={formData[key] || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-gray-500"
                    >
                      <IconExternalLink className="size-6" strokeWidth={2} />
                    </a>
                  ) : (
                    <span className="text-lg text-gray-600">{formData[key] || "-"}</span>
                  )}
                </div>
              ))}
            </div>

            {/* Input Fields */}
            <div className="space-y-6">
              <label className="block">
                <span className="text-lg font-bold text-gray-800">Registry Project</span>
                <input
                  name="registryProject"
                  type="text"
                  value={formData.registryProject}
                  onChange={handleChange}
                  className="mt-1 block w-full h-12 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-3"
                />
              </label>

              <label className="block">
                <span className="text-lg font-bold text-gray-800">Registry Record</span>
                <input
                  name="registryRecord"
                  type="text"
                  value={formData.registryRecord}
                  onChange={handleChange}
                  className="mt-1 block w-full h-12 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-3"
                />
              </label>

              <label className="block">
                <span className="text-lg font-bold text-gray-800">Start ID</span>
                <input
                  name="startId"
                  type="text"
                  value={formData.startId}
                  onChange={handleChange}
                  className="mt-1 block w-full h-12 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-3"
                />
              </label>

              <label className="block">
                <span className="text-lg font-bold text-gray-800">End ID</span>
                <input
                  name="endId"
                  type="text"
                  value={formData.endId}
                  onChange={handleChange}
                  className="mt-1 block w-full h-12 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-3"
                />
              </label>

              <label className="block">
                <span className="text-lg font-bold text-gray-800">Asset Name</span>
                <input
                  name="assetName"
                  type="text"
                  value={formData.assetName}
                  onChange={handleChange}
                  className="mt-1 block w-full h-12 border border-cD9 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-lg p-3"
                />
              </label>

              {error && <p className="text-red-500 text-sm">{error}</p>}
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end space-x-4 mt-8">
              <Button
                title="Cancel"
                onClick={onClose}
                className="bg-gray-300 text-gray-800 hover:bg-gray-400 py-2 px-6 rounded-md"
              />
              <Button
                title="Save"
                onClick={handleSave}
                className={`bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-md ${isSaveDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                isDisabled={isSaveDisabled}
              />
            </div>
          </div>
        </div>
      ) : (
        <ConfirmationModal
          isOpen={isConfirmationStep}
          editedFields={modifiedFields} // Pass only fields edited in this session
          onConfirm={handleConfirmSave}
          onCancel={() => setIsConfirmationStep(false)}
        />
      )}
    </>
  );
};

export default EditInventoryModal;
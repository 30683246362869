import React, { useEffect, useState } from "react";
import Layout from "../shared/Layout";
import Table from "../widgets/Table";
import { inventoryColumns } from "../data/InventoryTableData";
import Pagination from "../widgets/Pagination";
import PageTitle from "../widgets/PageTitle";
import { Bars } from "react-loader-spinner";
import { getInventoryData, updateInventoryItem, getRegistryData, getStatusData } from "../services/InventoryService";
import DropDownFilter from "../components/Filters/DropDownFilter";
import DocumentSort from "../components/Sorts/DocumentSort";
import inventoryModel from "../models/InventoryModel";
import EditInventoryModal from '../components/EditInventoryModal';
import SearchFilter from "../components/Filters/SearchFilter";

const Inventory = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [assetCode, setAssetCode] = useState(null);
  const [status, setStatus] = useState(null);
  const [registry, setRegistry] = useState(null);
  const [sortType, setSortType] = useState({ key: "date-created", order: "desc" });
  const [statusNames, setStatusNames] = useState([]);
  const [registryNames, setRegistryNames] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const statusNamesData = await getStatusData();
          const registryNamesData = await getRegistryData();
    
          const statusNames = statusNamesData.map((status) => status["status-name"]);
          const registryNames = registryNamesData.map((registry) => registry["registry-name"]);
    
          setStatusNames(statusNames);
          setRegistryNames(registryNames);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
      fetchData();
  }, []);
    
  const createOptions = (names) => names.map((name) => ({ label: name, value: name }));    

  useEffect(() => {
    fetchData();
  }, [currentPage, assetCode, status, registry, sortType]);


  const fetchData = async () => {
      setLoading(true);
      setErrorMsg("");
      try {
        const rowsPerPage = 10;
        const startRow = currentPage * rowsPerPage;
    
        const inventoryResponse = await getInventoryData(
          status,
          registry,
          rowsPerPage,
          startRow,
          assetCode,
          sortType
        );
    
        if (inventoryResponse.status) {
          const inventoryData = inventoryModel.parseList(inventoryResponse.data || []);
          setData(inventoryData);
    
          setErrorMsg("");
          setTotalPages(Math.ceil(inventoryResponse.totalRows / rowsPerPage));
        } else {
          setData([]);
          setErrorMsg(inventoryResponse.message);
        }
      } catch (error) {
        setData([]);
        setErrorMsg("Failed to fetch inventory data.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    
  const handleRowClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const onFilterChange = (filterType, value) => {
      setCurrentPage(0);
    
      if (filterType === "status") setStatus(value);
      if (filterType === "registry") setRegistry(value);
      if (filterType === "assetCode") setAssetCode(value);
    
      fetchData({
        status: filterType === "status" ? value : status,
        registry: filterType === "registry" ? value : registry,
        assetCode: filterType === "assetCode" ? value : assetCode,
      });
    };      

  const onSortChange = (sort) => {
    setCurrentPage(0);
    setSortType(sort);
  };

  const handleSave = async (updatedItem) => {
    try {
      await updateInventoryItem(updatedItem);
      setData((prevData) =>
        prevData.map((item) => (item.id === updatedItem.id ? updatedItem : item))
      );
      setErrorMsg(""); // Clear any previous errors
      setIsModalOpen(false); // Close modal after successful update
    } catch (error) {
      setErrorMsg("Failed to update inventory item.");
      console.error(error);
    }
  };

  const dataWithHandlers = data.map((item) => ({
    ...item,
    handleRowClick: () => handleRowClick(item),
  }));

  const clickableColumns = inventoryColumns.map((column) => ({
    ...column,
    cell: (props) => (
      <div
        className="cursor-pointer"
        onClick={() => props.row.original.handleRowClick()}
      >
        {column.cell ? column.cell(props) : props.getValue()}
      </div>
    ),
  }));

  return (
    <Layout className="space-y-6">
      <div className="flex items-center justify-between">
        <PageTitle title="Inventory" level={1} />
        <div className="flex gap-4">
          <SearchFilter
            label="Asset Code"
            placeholder="Type and press Enter..."
            onSearchChange={(value) => onFilterChange("assetCode", value)}
            mandatory={false}
          />
          <DropDownFilter
            label="Status"
            data={createOptions(statusNames)}
            selectedValue={status}
            onFilterChange={(value) => onFilterChange("status", value)}
          />
          <DropDownFilter
            label="Registry"
            data={createOptions(registryNames)}
            selectedValue={registry}
            onFilterChange={(value) => onFilterChange("registry", value)}
          />
          <DocumentSort
            label="Sort By"
            data={[
              { label: "Date Created", value: { key: "date-created", order: "desc" } },
              { label: "Status", value: { key: "status", order: "asc" } },
            ]}
            selectedValue={sortType}
            onFilterchange={(_, selectedOption) => onSortChange(selectedOption.value)}
          />
        </div>
      </div>
      {loading ? (
        <div className="text-center py-10 flex justify-center">
          <Bars height="80" width="80" color="rgb(78,183,63)" ariaLabel="bars-loading" />
        </div>
      ) : errorMsg ? (
        <div className="text-red-500">{errorMsg}</div>
      ) : (
        <Table data={dataWithHandlers} columns={clickableColumns} />
      )}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          onPageChange={(type) => {
            setCurrentPage((prevPage) => {
              if (type === "next" && prevPage < totalPages - 1) return prevPage + 1;
              if (type === "prev" && prevPage > 0) return prevPage - 1;
              return prevPage;
            });
          }}
        />
      )}

      <EditInventoryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        inventoryItem={selectedItem}
        onSave={handleSave}
      />
    </Layout>
  );
};

export default Inventory;

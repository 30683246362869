import React from "react";
import Select from "../../widgets/Select";

const DropDownFilter = ({ label, data, selectedValue, onFilterChange }) => {
  const defaultOption = { label: "All", value: null };
  const options = [defaultOption, ...data];

  return (
    <>
      <Select
        label={label}
        className="w-52 xl:w-60"
        value={options.find(option => option.value === selectedValue) || defaultOption}
        onChange={(selected) => onFilterChange(selected?.value || null)}
        defaultOptions={options}
        placeholder="Select..."
        isSearchable={true}
      />
    </>
  );
};

export default DropDownFilter;

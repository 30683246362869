import React from "react";
import IconLeft from "../assets/icons/IconLeft";
import IconRight from "../assets/icons/IconRight";

const Pagination = ({ className, totalPage, currentPage, onPageChange }) => {
  return (
    <div className={`relative flex-center gap-4 ${className}`}>
      <button
        type="button"
        className={
          currentPage === 0
            ? "text-lightgray"
            : "text-primary hover:text-black transition-all"
        }
        onClick={() => {
          if (currentPage > 0) {
            onPageChange("prev");
          }
        }}
        disabled={currentPage === 0}
      >
        <IconLeft strokeWidth={2} className="size-7 2xl:size-8" />
      </button>
      <p className="text-lg 2xl:text-xl font-bold text-primary">
        {currentPage + 1} of {totalPage}
      </p>
      <button
        type="button"
        className={
          currentPage >= totalPage - 1
            ? "text-lightgray"
            : "text-primary hover:text-black transition-all"
        }
        onClick={() => {
          if (currentPage < totalPage - 1) {
            onPageChange("next");
          }
        }}
        disabled={currentPage >= totalPage - 1}
      >
        <IconRight strokeWidth={2} className="size-7 2xl:size-8" />
      </button>
    </div>
  );
};

export default Pagination;
import React from "react";

const IconExternalLink = ({ className, strokeWidth = 1.5, creationUrl }) => {
  return (
    <svg
      data-tooltip-id="creation-url"
      data-tooltip-content={creationUrl}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      className={`text-current hover:text-primary transition-all ${className}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
      />
    </svg>
  );
};

export default IconExternalLink;

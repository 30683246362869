const hModel = {
  parseList(data) {
    let items = [];
    data.forEach((element) => {
      items.push({
        id: element["account-id"],
        account: element["account-name"] ? element["account-name"] : "",
        symbol: element.asset.code,
        type: element["account-type"] ? element["account-type"] : "",
        name: element.asset.description,
        inOrder: element.quantities["in-order"],
        withdrawing: element.quantities["pending-withdrawal"],
        available: element.quantities.available,
      });
    });
    return items;
  },
  parseGroups(data) {
    var items = [];
    data.forEach((element) => {
      items.push({
        id: element.id,
        name: element.name,
        longName: element["long-name"],
      });
    });
    return items;
  },
  parseAccount(data) {
    var items = [];
    data.forEach((element) => {
      items.push({
        id: element.id,
        name: element.name,
        longName: element["long-name"],
        type: element.type,
        status: element.status,
        email: element.email,
      });
    });
    return items;
  },
};
export default hModel;

import { api } from "../helper/config";
import DocumentModel from "../models/DocumentModel";
import axios from "axios";
import { getAPIHeader, parseJWTDetail } from "./CommonService";
import InstrumentModel from "../models/InstrumentModel";

export const getDocumentTypes = () => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    const url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      api.METHOD_DOCUMENT_TYPES;

    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        resolve({
          status: true,
          data: InstrumentModel.parseSegment(response.data.data),
        });
      })
      .catch((error) => {
        resolve({
          status: false,
          message: error.message,
        });
      });
  });
};

export const getDocuments = (params) => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    let url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      "/participant/" +
      parseJWTDetail("participant") +
      "/documents?start-row=" +
      params.page;
    if (params.type !== "all") {
      url = url + "&type=" + params.type;
    }

    if (params.dateStart !== null) {
      url = url + "&date-from=" + params.dateStart;
    }

    if (params.dateEnd !== null) {
      url = url + "&date-to=" + params.dateEnd;
    }
    if (params.sortBy) {
      url = url + "&sort-by=" + params.sortBy;
      if (params.sortBy === "date-published") {
        url = url + "&order=DESC";
      }
    }
    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        let pageDetails = response.data["page-details"];
        resolve({
          status: true,
          statusCode: response.status,
          data: response.data.data
            ? DocumentModel.parseList(response.data.data)
            : [],
          totalRows: pageDetails["total-rows"],
        });
      })
      .catch((error) => {
        let statusCode = 404;
        if (error.response) {
          statusCode = error.response.status;
        }
        resolve({
          status: false,
          statusCode: statusCode,
          message: error.message,
        });
      });
  });
};

export const getPublicDocuments = (params) => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      "/documents" +
      "?start-row=" +
      params.page;
    url = url + "&instrument-id=" + params.instrument;

    if (params.type !== "all") {
      url = url + "&type=" + params.type;
    }

    if (params.dateStart !== null) {
      url = url + "&date-from=" + params.dateStart;
    }

    if (params.dateEnd !== null) {
      url = url + "&date-to=" + params.dateEnd;
    }

    if (params.sortBy) {
      url = url + "&sort-by=" + params.sortBy;
      if (params.sortBy === "date-published") {
        url = url + "&order=DESC";
      }
    }

    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        let pageDetails = response.data["page-details"];
        resolve({
          status: true,
          statusCode: response.status,
          data: response.data.data
            ? DocumentModel.parseList(response.data.data, params.accounts)
            : [],
          totalRows: pageDetails["total-rows"],
        });
      })
      .catch((error) => {
        let statusCode = 404;
        if (error.response) {
          statusCode = error.response.status;
        }
        resolve({
          status: false,
          statusCode: statusCode,
          message: error.message,
        });
      });
  });
};

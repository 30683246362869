import React, { useState } from "react";

const SearchFilter = ({ label = "", placeholder = "Search...", onSearchChange, mandatory }) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const trimmedValue = inputValue.trim();
      onSearchChange(trimmedValue === "" ? null : trimmedValue);
    }
  };

  const customStyles = {
    input:
      "w-full border border-gray-300 rounded-md px-3 text-base text-black focus:outline-none focus:border-[#4EB73F]",
    container: "relative w-44 xl:w-52",
    label: "absolute -top-4 left-3 px-2 text-base text-black z-[1] bg-white",
  };

  return (
    <div className={customStyles.container}>
      {label?.length > 0 && (
        <label className={customStyles.label}>
          {label} {mandatory && <span className="text-red">*</span>}
        </label>
      )}
      <div className="relative flex items-center w-full">
        <input
          type="text"
          className={customStyles.input}
          style={{ height: "50px" }}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default SearchFilter;
import React from "react"
import Listings from "../pages/Listings"
import { createBrowserRouter } from "react-router-dom"
import Documents from "../pages/Documents"
import Holding from "../pages/Holding"
import Inventory from "../pages/Inventory"
export const routeList = createBrowserRouter([
  {
    path: "/",
    element: <Listings />,
  },
  {
    path: "/documents",
    element: <Documents />,
  },
  {
    path: "/holding",
    element: <Holding />,
  },
  {
    path: "/inventory",
    element: <Inventory />,
  },
])

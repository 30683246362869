import axios from 'axios';

export const getInventoryData = (
      status,
      registry,
      rows = 10,
      startRow = 0,
      assetCode = "",
      sortType = { key: "status", order: "asc" }
    ) => {
      return new Promise((resolve, reject) => {
        const params = {
          status,
          "sort-by": sortType.key,
          order: sortType.order,
          rows,
          "start-row": startRow,
        };
        if (registry) params.registry = registry;
        if (assetCode) params["asset-code"] = assetCode;

        const url = window.env.INVENTORY_API_BASE_URI + "/data/registry/inventory";
    
        axios
          .get(url, {
            params,
          })
          .then((response) => {
            resolve({
              status: true,
              data: response.data.data,
              totalRows: response.data?.["page-details"]?.["total-rows"] || 0,
            });
          })
          .catch((error) => {
            resolve({
              status: false,
              message: error.response?.data?.error?.[0]?.msg || "Failed to fetch inventory data.",
            });
          });
      });
    };

export const updateInventoryItem = async (item) => {
  try {
    const url = `${window.env.INVENTORY_API_BASE_URI}/data/registry/inventory`; // Construct the URL

    const headers = {};

    const requestBody = {
      id: item.id,
      data: {
        "registry-project": item.registryProject,
        "registry-record": item.registryRecord,
        "start-id": item.startId,
        "end-id": item.endId,
        "asset-name": item.assetName,
      },
    };

    const response = await axios.put(url, requestBody, { headers });

    return response.data;
  } catch (error) {
    console.error("Error updating inventory item:", error.response?.data || error.message);
    throw error;
  }
};

export const getRegistryData = async () => {
      try {
        const response = await axios.get(`${window.env.INVENTORY_API_BASE_URI}/data/registry/registries`);
        return response.data?.data || [];
      } catch (error) {
        console.error("Error fetching registry data:", error);
        return [];
      }
};

export const getStatusData = async () => {
      try {
        const response = await axios.get(`${window.env.INVENTORY_API_BASE_URI}/data/registry/statuses`);
        return response.data?.data || [];
      } catch (error) {
        console.error("Error fetching status data:", error);
        return [];
      }
};
    
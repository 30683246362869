import { createColumnHelper } from "@tanstack/react-table";
import IconExternalLink from "../assets/icons/IconExternalLink";
import { Tooltip } from "react-tooltip";

const columnHelper = createColumnHelper();

export const inventoryColumns = [
  columnHelper.accessor("date", {
    header: () => "Date",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("time", {
    header: () => "Time",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetCode", {
    header: () => "Asset Code",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("assetName", {
    header: () => "Asset Name",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registry", {
    header: () => "Registry",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registryProject", {
    header: () => "Registry Project",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("registryRecord", {
    header: () => "Registry Record",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("quantity", {
    header: () => "Number of COUs",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("startId", {
    header: () => "Start ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("endId", {
    header: () => "End ID",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("chain", {
    header: () => "Chain",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("creationUrl", {
    header: () => "Creation URL",
    cell: (props) => (
      <>
        <Tooltip id="creation-url" place="top" variant="light" size="sm" />
        <a
          href={props.getValue() || "#"}
          target="_blank"
          rel="noopener noreferrer"
          type="button"
        >
          <IconExternalLink 
            className="size-6" 
            strokeWidth={2} 
            creationUrl={props.getValue()} 
          />
        </a>
      </>
    ),
  }),
  columnHelper.accessor("numberRetired", {
    header: () => "Number Retired",
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("numberOutstanding", {
    header: () => "Number Outstanding",
    cell: (props) => {
      const quantity = props.row.original.quantity || 0;
      const numberRetired = props.row.original.numberRetired || 0;
      return quantity - numberRetired;
    },
  }),
  columnHelper.accessor("status", {
    header: () => "Status",
    cell: (props) => props.getValue() || "-",
  }),
];
import moment from "moment";
const dModel = {
  parseList(data) {
    let items = [];
    data.forEach((element) => {
      items.push({
        id: element["account-id"],
        description: element.name,
        download: element.url,
        size: element.size,
        date: moment(element["date-published"]).format("D MMM YYYY"),
        account: element["account-name"],
        symbol: element["asset-code"] ? element["asset-code"] : "",
        type: element.type,
      });
    });
    return items;
  },
};
export default dModel;

import React, { useEffect, useState } from "react";
import Layout from "../shared/Layout";
import ListCard from "../components/Listings/ListCard";
import Pagination from "../widgets/Pagination";
import SegmentFilter from "../components/Filters/SegmentFilter";
import TypeFilter from "../components/Filters/TypeFilter";
import PageTitle from "../widgets/PageTitle";
import { Bars } from "react-loader-spinner";
import {
  getInstrumentDetail,
  getInstruments,
  getInstrumentSegment,
  getInstrumentTypes,
} from "../services/InstrumentService";
import { getExchangeConfig } from "../helper/platformConfig";

const Listings = () => {
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [instrumentSegments, setInstrumentSegments] = useState([]);
  const [currentSegment, setCurrentSegment] = useState("all");
  const [instrumentTypes, setInstrumentTypes] = useState([]);
  const [currentType, setCurrentType] = useState("all");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    initFields();
  }, []);

  useEffect(() => {
    setLoading(true);
    getInstrumentsAPI(currentPage, currentType, currentSegment);
  }, [currentPage, currentType, currentSegment]);

  const initFields = () => {
    getSegmentAPI();
    getTypeAPI();
  };

  const getSegmentAPI = () => {
    getInstrumentSegment().then((result) => {
      let instrumentSegmentData = [];
      if (result.status) {
        instrumentSegmentData = result.data;
      }
      setInstrumentSegments(instrumentSegmentData);
      setCurrentSegment("all");
    });
  };

  const getTypeAPI = () => {
    getInstrumentTypes().then((result) => {
      let instrumentTypeData = [];
      if (result.status) {
        instrumentTypeData = result.data;
      }
      setInstrumentTypes(instrumentTypeData);
      setCurrentType("all");
    });
  };

  const getInstrumentsAPI = (page, type, segment) => {
    const rowsPerPage = 5;
    
    getInstruments({
      page,
      type,
      segment,
    }).then((result) => {
      let instrumentData = [];
      if (result.status) {
        instrumentData = result.data;
        setTotalPages(Math.ceil(result.totalRows / rowsPerPage));
      } else {
        setErrorMsg(
          result.statusCode === 401
            ? "You are not authorised."
            : "No Data Available",
        );
      }
      processInstrumentDetail(instrumentData);
    });
  };

  const processInstrumentDetail = async (data) => {
    let instrumentData = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const result = await getInstrumentDetail({
        id: element.id,
      });
      if (result.status) {
        element.img = result.data.logo;
        element.short = result.data.short;
        instrumentData.push(element);
      }
    }
    setData(instrumentData);
    setLoading(false);
  };

  const onPageChange = (pagingType) => {
    if (loading) {
      return;
    }
    if (pagingType === "next") {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const onFilterchange = (filterType, filterValue) => {
    if (loading) {
      return;
    }
    setCurrentPage(0);
    if (filterType === "segment") {
      setCurrentSegment(filterValue.value);
    } else {
      setCurrentType(filterValue.value);
    }
  };

  return (
    <Layout className="space-y-6">
      <div className="relative lg:flex xl:flex items-center justify-between gap-6">
        <PageTitle title={getExchangeConfig().sidebarLabels[0]} level={1} />
        <div className="relative flex items-center xl:justify-end lg:justify-end gap-6 mt-5 xl:mt-0 lg:mt-0">
          <SegmentFilter
            data={instrumentSegments}
            onFilterchange={onFilterchange}
          />
          <TypeFilter data={instrumentTypes} onFilterchange={onFilterchange} />
        </div>
      </div>
      {loading && (
        <div className="text-center py-10 flex justify-center">
          <Bars
            height="80"
            width="80"
            color="rgb(78,183,63)"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-loading"
            visible={true}
          />
        </div>
      )}
      {!loading && data.length > 0 && (
        <div className="relative grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 items-start">
          {data.map((items, i) => (
            <ListCard
              id={items.id}
              key={i}
              img={items.img}
              title={items.title}
              href={items.href}
              segment={items.segment}
              type={items.type}
              symbol={items.symbol}
              ccy={items.ccy}
              short={items.short}
            />
          ))}
        </div>
      )}

      {!loading && data.length === 0 && (
        <div className="text-center py-10">{errorMsg}</div>
      )}

      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          onPageChange={onPageChange}
        />
      )}
    </Layout>
  );
};

export default Listings;

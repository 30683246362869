export const routes = {
  auth: "/login",
  listing: "/",
  documents: "/documents",
  holding: "/holding",
  listingDetail: "/instrument/:id",
  inventory: "/inventory",
}

export default routes

import React, { useEffect, useState } from "react";
import Layout from "../shared/Layout";
import TopDetailBox from "../components/ListingDetail/TopDetailBox";
import Table from "../widgets/Table";
import { useLocation, useParams } from "react-router-dom";
import { getInstrumentDetail } from "../services/InstrumentService";
import { Bars } from "react-loader-spinner";
import {
  getDocumentTypes,
  getPublicDocuments,
} from "../services/DocumentService";
import Pagination from "../widgets/Pagination";
import { pubDocColumns } from "../data/DocumentsTableData";
import { useAtom } from "jotai";
import { accountsAtom, initLoadingAtom } from "../helper/store";
import TypeFilter from "../components/Filters/TypeFilter";
import RangeFilter from "../components/Filters/RangeFilter";
import moment from "moment";
import DocumentSort from "../components/Sorts/DocumentSort";

const ListingDetail = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [detail, setDetail] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  // const [errorMsg, setErrorMsg] = useState("");
  const [accounts] = useAtom(accountsAtom);
  const [initLoading] = useAtom(initLoadingAtom);

  const [documentTypes, setDocumentTypes] = useState([]);
  const [currentType, setCurrentType] = useState("all");
  const [sortType, setSortType] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [docLoading, setDocLoading] = useState(false);

  useEffect(() => {
    if (!initLoading) {
      getListingDetail();
      getTypeAPI();
    }
  }, [initLoading]);

  const getListingDetail = () => {
    getInstrumentDetail({
      id: params.id,
    }).then((result) => {
      if (result.status) {
        let data = result.data;
        data.title = location.state.title;
        data.segment = location.state.segment;
        data.type = location.state.type;
        data.symbol = location.state.symbol;
        setDetail(data);
      } else {
        setDetail(null);
        // setErrorMsg(
        //   result.statusCode === 401
        //     ? "You are not authorised."
        //     : "No Data Available",
        // );
      }
      setLoading(false);
      getDocumentAPI(currentPage, currentType, startDate, endDate);
    });
  };

  const getDocumentAPI = (page, type, dateStart, dateEnd, sortBy) => {
    setDocLoading(true);
    getPublicDocuments({
      page,
      instrument: params.id,
      accounts: accounts,
      type,
      dateStart,
      dateEnd,
      sortBy,
    }).then((result) => {
      let documentData = [];
      if (result.status) {
        documentData = result.data;
        setTotalPages(result.totalRows);
      }
      setData(documentData);
      setDocLoading(false);
    });
  };

  const getTypeAPI = () => {
    getDocumentTypes().then((result) => {
      let documentTypeData = [];
      if (result.status) {
        documentTypeData = result.data;
      }
      setDocumentTypes(documentTypeData);
      setCurrentType("all");
    });
  };

  const onPageChange = (pagingType) => {
    if (loading) {
      return;
    }
    let startRow = currentPage;
    if (pagingType === "next") {
      startRow = startRow + 10;
    } else {
      startRow = startRow - 10;
    }
    setCurrentPage(startRow);
    getDocumentAPI(startRow, currentType, startDate, endDate);
  };

  const onFilterchange = (filterType, filterValue) => {
    if (loading) {
      return;
    }
    setCurrentPage(0);
    if (filterType === "range") {
      let newStartDate = null;
      let newEndDate = null;
      if (filterValue == null) {
        setStartDate(null);
        setEndDate(null);
      } else {
        if (filterValue[0] == null) {
          setStartDate(null);
          setEndDate(null);
        } else {
          newStartDate = moment(filterValue[0]).format("YYYY-MM-DD");
          newEndDate = moment(filterValue[1]).format("YYYY-MM-DD");
          setStartDate(newStartDate);
          setEndDate(newEndDate);
        }
      }

      getDocumentAPI(
        currentPage,
        currentType,
        newStartDate,
        newEndDate,
        sortType,
      );
    } else {
      setCurrentType(filterValue.value);
      getDocumentAPI(
        currentPage,
        filterValue.value,
        startDate,
        endDate,
        sortType,
      );
    }
  };
  const onSortchange = (_, selectedOption) => {
    if (loading) {
      return;
    }
    setCurrentPage(0);
    setSortType(selectedOption.value);
    getDocumentAPI(
      currentPage,
      currentType,
      startDate,
      endDate,
      selectedOption.value,
    );
  };

  return (
    <Layout className="space-y-8">
      {loading && (
        <div className="text-center py-10 flex justify-center">
          <Bars
            height="80"
            width="80"
            color="rgb(78,183,63)"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-loading"
            visible={true}
          />
        </div>
      )}
      {!loading && !detail && (
        <div className="text-center py-10">No Data Available</div>
      )}
      {!loading && detail && (
        <>
          <TopDetailBox detail={detail} />

          <div className="relative flex items-center justify-end gap-6">
            <div className="relative flex items-center justify-end gap-6">
              <RangeFilter
                startDate={null}
                endDate={null}
                onFilterchange={onFilterchange}
              />
              <TypeFilter
                data={documentTypes}
                onFilterchange={onFilterchange}
              />
              <DocumentSort
                data={[
                  {
                    label: "None",
                    value: null,
                  },
                  {
                    label: "Description",
                    value: "name",
                  },
                  {
                    label: "Type",
                    value: "type",
                  },
                  // {
                  //   label: "Size",
                  //   value: "size",
                  // },
                  {
                    label: "Date",
                    value: "date-published",
                  },
                ]}
                onFilterchange={onSortchange}
              />
            </div>
          </div>
          {!docLoading && data.length === 0 && (
            <div className="text-center py-10">No Data Available</div>
          )}

          {docLoading && (
            <div className="text-center py-10 flex justify-center">
              <Bars
                height="80"
                width="80"
                color="rgb(78,183,63)"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars-loading"
                visible={true}
              />
            </div>
          )}

          {!docLoading && data.length > 0 && (
            <>
              <Table columns={pubDocColumns} data={data} />
              {totalPages > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalPage={totalPages}
                  onPageChange={onPageChange}
                />
              )}
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default ListingDetail;
